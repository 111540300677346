import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, fromEvent, Observable, of, Subscription, TeardownLogic } from 'rxjs';

import { SessionStorage } from 'ngx-webstorage';

@Injectable({
    providedIn: 'root',
})
export class ColorThemeService implements OnDestroy {
    set subrelease(sub: TeardownLogic) {
        this._subrelease.add(sub);
    }
    private _subrelease = new Subscription();

    @SessionStorage() private _CurrentTheme!: ThemeType | null;
    private _CurrentThemeSub = new BehaviorSubject<ThemeType | null>(null);
    get CurrentThemeSub() {
        return this._CurrentThemeSub.asObservable();
    }
    get CurrentTheme() {
        return this._CurrentThemeSub.value;
    }

    constructor() {
        // Set the initial color theme. If we have a current session storage value set, then defer to that automatically. If not,
        // we try to use the system's color theme preference (Windows, Mac, Android, etc).
        if (!this._CurrentTheme) {
            const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            // this._CurrentTheme = prefersDark ? 'my-dark-theme' : 'my-light-theme';
            this._CurrentThemeSub.next(prefersDark ? 'my-dark-theme' : 'my-light-theme');
        } else {
            this._CurrentThemeSub.next(this._CurrentTheme);
        }

        // Watch for changes to the system default theme, so we can potentially change the app theme (if a user override isn't present).
        this.subrelease = fromEvent<MediaQueryList>(window.matchMedia('(prefers-color-scheme: dark)'), 'change').subscribe((ev) => {
            if (!this._CurrentTheme) {
                // There is still no override set. Defer to the new system default theme.
                this._CurrentThemeSub.next(ev.matches ? 'my-dark-theme' : 'my-light-theme');
            }
        });
    }

    ngOnDestroy(): void {
        this._subrelease.unsubscribe();
        this._CurrentThemeSub.complete();
    }

    // #region Set Theme

    SetTheme(theme: ThemeType): void {
        // Set the user selected theme.
        this._CurrentTheme = theme;
        this._CurrentThemeSub.next(this._CurrentTheme);
    }

    // #endregion
}

export type ThemeType = 'my-light-theme' | 'my-dark-theme';
