import { NgModule, Component, Inject, Injectable, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarModule, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { Observable } from 'rxjs';

export interface SplashOptions {
    TitleText?: string;
    MessageText?: string;
    ShowProgressBar?: boolean;
}

// #region Splash Dialog Component

@Component({
    selector: 'dialog-splash-component',
    styles: [
        `
            .mat-snack-bar-container.success {
                border-left: 10px solid green;
            }

            .mat-snack-bar-container.error {
                border-left: 10px solid red;
            }

            .mat-snack-bar-container.alert {
                border-left: 10px solid rgba(255, 255, 0, 0.555);
            }
        `,
    ],
    template: `
        <h1 mat-dialog-title class="dialog-title">{{ title }}</h1>
        <div mat-dialog-content class="dialog-message">{{ message }}</div>
        <mat-progress-bar *ngIf="showProgress" mode="indeterminate"></mat-progress-bar>
    `,
})
export class SplashDialogComponent {
    title: string;
    message: string;
    showProgress: boolean;

    constructor(public dialogRef: MatDialogRef<SplashDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: SplashOptions) {
        this.title = data.TitleText || '';
        this.message = data.MessageText || '';
        this.showProgress = data.ShowProgressBar || false;
    }
}

// #endregion

@Injectable({
    providedIn: 'root',
})
export class SplashService {
    private openHandle: MatDialogRef<SplashDialogComponent> | null = null;

    constructor(private dialog: MatDialog, private snackbar: MatSnackBar) {}

    // #region Close Splash Method

    CloseSplash(): void {
        if (this.openHandle) {
            this.openHandle.close();
        }

        this.openHandle = null;
    }

    // #endregion

    // #region Open Splash Method

    OpenSplash(options?: SplashOptions): Observable<any> {
        this.openHandle = this.dialog.open<SplashDialogComponent>(SplashDialogComponent, {
            disableClose: true,
            data: {
                TitleText: 'Please wait',
                MessageText: 'Loading...',
                ShowProgressBar: true,
                ...options,
            },
        });

        return this.openHandle.afterOpened();
    }

    // #endregion

    // #region Snackbar Alert Method

    SnackbarAlert(message: string, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
        const config: MatSnackBarConfig = {
            panelClass: ['alert'],
        };

        config.duration = duration != null ? duration : 3500;

        return this.snackbar.open(message, undefined, config);
    }

    // #endregion

    // #region Snackbar Error Method

    SnackbarError(message: string, duration?: number, hold?: boolean): MatSnackBarRef<TextOnlySnackBar> {
        const config: MatSnackBarConfig = {
            panelClass: ['error'],
        };

        let action: string | undefined = void 0;

        config.duration = duration || 5500;

        if (hold === true) {
            action = 'OK';
            config.duration = 5 * 60 * 1000; // 5 minutes?
        }

        return this.snackbar.open(message, action, config);
    }

    // #endregion

    // #region Snackbar Success Method

    SnackbarSuccess(message: string, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
        const config: MatSnackBarConfig = {
            panelClass: ['success'],
        };

        config.duration = duration || 3500;

        return this.snackbar.open(message, undefined, config);
    }

    // #endregion
}

@NgModule({
    imports: [CommonModule, MatDialogModule, MatProgressBarModule, MatSnackBarModule],
    declarations: [SplashDialogComponent],
    exports: [SplashDialogComponent],
})
export class SplashDialogModule {}
