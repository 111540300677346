import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoleTextPipe } from './roletext.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [RoleTextPipe],
    providers: [RoleTextPipe],
    exports: [RoleTextPipe],
})
export class RoleTextPipeModule {}
