import { BrowserModule, DomSanitizer, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Injectable, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';

import { JFlexLayoutModule } from 'modules/flex-layout/flex-layout.module';
import { NgxWebstorageModule } from 'ngx-webstorage';

// Echarts / Ngx-Charts
// import { NgxEchartsModule } from 'ngx-echarts';
// import * as echarts from 'echarts/core';
// import { BarChart } from 'echarts/charts';
// import {
//     TitleComponent,
//     TooltipComponent,
//     GridComponent,
//     ToolboxComponent,
//     LegendComponent,
//     DatasetComponent,
// } from 'echarts/components';
// import { BarSeriesOption } from 'echarts';
// import { CanvasRenderer } from 'echarts/renderers';

// export type ECOption = echarts.ComposeOption<BarSeriesOption>;

// echarts.use([
//     TitleComponent,
//     TooltipComponent,
//     GridComponent,
//     ToolboxComponent,
//     LegendComponent,
//     BarChart,
//     DatasetComponent,
//     CanvasRenderer,
// ]);

// // import 'echarts/theme/macarons.js';
// import { Custom_Dark_Theme } from './custom-dark-theme';
// import { Custom_Light_Theme } from './custom-light-theme';

// echarts.registerTheme('custom-dark', Custom_Dark_Theme);
// echarts.registerTheme('custom-light', Custom_Light_Theme);

// Replace as needed in your project modules
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

// Application Modules
import { RoleTextPipeModule } from 'pipes/roletext';
import { SplashDialogModule } from 'dialogs/splash';

import { AppComponent } from './components/app/app.component';
import { AppRoutingModule } from 'routes/app.routing-module';

// UI Components

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    events = ['doubleTap'];
    // overrides = {
    //     swipe: { direction: Hammer.DIRECTION_ALL },
    //     tap: { event: 'doubleTap', taps: 2 },
    //     // tslint:disable-next-line: no-any
    // } as any;
}

@NgModule({
    declarations: [
        AppComponent,

        // UI components
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HammerModule,
        HttpClientModule,
        JFlexLayoutModule,
        AppRoutingModule,
        NgxWebstorageModule.forRoot(),
        // NgxEchartsModule.forRoot({
        //     echarts,
        // }),

        MatButtonModule,
        MatIconModule,
        MatTooltipModule,

        // Application module imports
        RoleTextPipeModule,
        SplashDialogModule,
    ],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
        this.iconRegistry.addSvgIcon('customchartoutline', this.sanitizer.bypassSecurityTrustResourceUrl('chart_outlined.svg'));
    }
}
