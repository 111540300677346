import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    // {
    //     path: RoutePaths.Home,
    //     loadChildren: () => import('../modules/home/home.module').then((m) => m.HomeModule),
    // },
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('../modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
