import { Component, HostBinding, OnInit, OnDestroy, Inject } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';

import { Subscription, TeardownLogic } from 'rxjs';

import { ColorThemeService, ThemeType } from 'services/colortheme/colortheme.service';
import { ScreenSizeService } from 'services/screensize/screensize.service';
import { BUILD_NOTES, BUILD_TIME, VERSION_NUMBER } from 'services/version/version.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    @HostBinding('class.smalldisplay') SmallDisplay: boolean;

    private _subrelease: Subscription = new Subscription();
    set subrelease(sub: TeardownLogic) {
        this._subrelease.add(sub);
    }

    BuildNotes: string;
    BuildTime: string;
    CurrentRole: string;
    CurrentTheme: ThemeType | null = null;
    DevSite: boolean;
    LastSizeCode: string;
    LastSmallDisplay: boolean;
    SizeCode: string;
    UpdateAvailable: boolean;
    VersionLoaded: string;
    ViewReady: boolean;

    constructor(
        private colorThemeSvc: ColorThemeService,
        private datePipe: DatePipe,
        private screenSizeSvc: ScreenSizeService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.BuildNotes = BUILD_NOTES;
        this.CurrentRole = '';
        this.DevSite = false;
        this.LastSizeCode = '';
        this.SizeCode = '';
        this.SmallDisplay = false;
        this.LastSmallDisplay = false;
        this.UpdateAvailable = false;
        this.VersionLoaded = VERSION_NUMBER;
        this.ViewReady = false;

        const btime = BUILD_TIME && new Date(BUILD_TIME);
        this.BuildTime = (btime && this.datePipe.transform(btime, 'MMM. dd, yyyy h:mm a')) || '';

        // Subscribe to the color theme service, so we can set the CSS accordingly to changes.
        this.subrelease = this.colorThemeSvc.CurrentThemeSub.subscribe((theme) => {
            if (theme != null) {
                // Set the theme accordingly.
                this.CurrentTheme = theme;
                this.document.body.classList.remove(theme === 'my-light-theme' ? 'my-dark-theme' : 'my-light-theme');
                this.document.body.classList.add(theme);
            }
        });
    }

    ngOnDestroy(): void {
        this._subrelease.unsubscribe();
    }

    ngOnInit(): void {
        const self = this;

        // Subscribe to media changes
        self.subrelease = self.screenSizeSvc.ScreenSizeSub.subscribe((size) => {
            self.SmallDisplay = size.IsSmallDisplay;
            self.SizeCode = size.Alias;

            self.LastSmallDisplay = self.SmallDisplay;
        });

        self.SmallDisplay = self.screenSizeSvc.ScreenSize.IsSmallDisplay;
        self.LastSmallDisplay = self.SmallDisplay;
        self.SizeCode = self.screenSizeSvc.ScreenSize.Alias;

        self.ViewReady = true;
    }

    // #region Go To District Home

    GoToDistrictHome(): void {
        window.open('https://www.polkschoolsfl.com', '_self');
    }

    // #endregion

    // #region Toggle Theme

    ToggleTheme(): void {
        this.colorThemeSvc.SetTheme(this.CurrentTheme === 'my-light-theme' ? 'my-dark-theme' : 'my-light-theme');
    }

    // #endregion
}
