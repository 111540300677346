import { Injectable, OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// #region Custom Classes

// #region Screen Size Custom Query

export interface ScreenSizeCustomQuery {
    Key: string;
    Query: string;
}

// #endregion

// #region Screen Size Info

export interface ScreenSizeInfo {
    Alias: string;
    IsSmallDisplay: boolean;
    IsXs: boolean;
    IsSm: boolean;
    IsMd: boolean;
    IsLg: boolean;
    IsXl: boolean;
}

// #endregion

// #endregion

@Injectable({
    providedIn: 'root',
})
export class ScreenSizeService implements OnDestroy {
    private _subrelease: Subscription = new Subscription();
    private _ScreenSizeSub: BehaviorSubject<ScreenSizeInfo>;

    // #region Getters

    get ScreenSize(): ScreenSizeInfo {
        return this._ScreenSizeSub.value;
    }
    get ScreenSizeSub(): BehaviorSubject<ScreenSizeInfo> {
        return this._ScreenSizeSub;
    }

    // #endregion

    constructor(private mediaService: MediaObserver) {
        // Set the initial upkeep
        const info = {
            Alias: '',
            IsSmallDisplay: false,
            IsXs: this.mediaService.isActive('xs'),
            IsSm: this.mediaService.isActive('sm'),
            IsMd: this.mediaService.isActive('md'),
            IsLg: this.mediaService.isActive('lg'),
            IsXl: this.mediaService.isActive('xl'),
        } as ScreenSizeInfo;

        info.IsSmallDisplay = info.IsXs || info.IsSm;
        info.Alias = info.IsXs ? 'xs' : info.IsSm ? 'sm' : info.IsMd ? 'md' : info.IsLg ? 'lg' : 'xl';

        this._ScreenSizeSub = new BehaviorSubject<ScreenSizeInfo>(info);

        // Set up the subscription to keep up changes
        this._subrelease.add(
            this.mediaService
                .asObservable()
                .pipe(debounceTime(250))
                .subscribe((armedia: any) => {
                    const media = {
                        mqAlias: armedia && !!armedia.length ? armedia[0].mqAlias : '',
                    };

                    const obsinfo = {
                        Alias: media.mqAlias,
                        IsSmallDisplay: media.mqAlias === 'xs' || media.mqAlias === 'sm',
                        IsXs: media.mqAlias === 'xs',
                        IsSm: media.mqAlias === 'sm',
                        IsMd: media.mqAlias === 'md',
                        IsLg: media.mqAlias === 'lg',
                        IsXl: media.mqAlias === 'xl',
                    } as ScreenSizeInfo;

                    this._ScreenSizeSub.next(obsinfo);
                })
        );
    }

    ngOnDestroy(): void {
        this._subrelease.unsubscribe();
    }
}
