import { NgModule } from '@angular/core';
import { FlexLayoutModule, LAYOUT_CONFIG, MediaMarshaller } from '@angular/flex-layout';

@NgModule({
    imports: [
        {
            ngModule: FlexLayoutModule,
            providers: [
                {
                    provide: LAYOUT_CONFIG,
                    useValue: {
                        addFlexToParent: true,
                        addOrientationBps: false,
                        disableDefaultBps: false,
                        disableVendorPrefixes: false,
                        serverLoaded: false,
                        useColumnBasisZero: false,
                    },
                },
            ],
        },
    ],
    exports: [FlexLayoutModule],
})
export class JFlexLayoutModule {
    // tslint:disable-next-line: no-any
    private lastValue: any[] = [];

    constructor(private m: MediaMarshaller) {
        // hack until resolve: https://github.com/angular/flex-layout/issues/1201
        // @ts-ignore
        m.subject.subscribe((x) => {
            // @ts-ignore
            if (m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
                // @ts-ignore
                this.lastValue = [...m.activatedBreakpoints];
            } else {
                // @ts-ignore
                m.activatedBreakpoints = [...this.lastValue];
                // @ts-ignore
                m.hook.collectActivations = () => {};
                // @ts-ignore
                m.hook.deactivations = [...this.lastValue];
            }
        });
    }
}
