import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roleText',
})
export class RoleTextPipe implements PipeTransform {
    // tslint:disable-next-line: no-any
    transform(value?: string | UserRoleInfo | null): string {
        if (!value) {
            return '';
        }

        const roleName = typeof value === 'string' ? value : value.RoleName;

        switch (roleName) {
            case SystemRole.Developer:
                return 'Developer';
            case SystemRole.SystemAdmin:
                return 'System Admin';
            // Add other roles here.
            default:
                return '';
        }
    }
}

// #region User Role Info

export interface UserRoleInfo {
    RoleName: SystemRole;
    WorkLocation: string;
    IsDeveloper: boolean;
    IsSystemAdmin: boolean;
    // Add other roles here.
}

// #endregion

export enum SystemRole {
    All = 'all',
    Developer = 'Developer',
    SystemAdmin = 'SystemAdmin',
    // Add other roles here.
}
