<div class="outerwrap" fxLayout="column" fxLayoutAlign="start stretch" [hidden]="!ViewReady">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="title-line">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <h1
                [ngClass.sm]="['mat-display-1', 'margin-bottom-none']"
                [ngClass.xs]="'mat-headline'"
                [ngClass.md]="'mat-display-1'"
                [ngClass.gt-md]="'mat-display-3'"
            >
                COVID<span fxHide.xs>-19</span> Dashboard
            </h1>
            <button
                type="button"
                mat-mini-fab
                (click)="ToggleTheme()"
                [matTooltip]="CurrentTheme === 'my-light-theme' ? 'Switch to dark mode' : 'Switch to light mode'"
                [title]="CurrentTheme === 'my-light-theme' ? 'Switch to dark mode' : 'Switch to light mode'"
            >
                <mat-icon>{{ CurrentTheme === 'my-light-theme' ? 'dark_mode' : 'light_mode' }}</mat-icon>
            </button>
        </div>
        <!-- <div>
            <span fxHide fxShow.xl>xl</span>
            <span fxHide fxShow.lg>lg</span>
            <span fxHide fxShow.md>md</span>
            <span fxHide fxShow.sm>sm</span>
            <span fxHide fxShow.xs>xs</span>
        </div> -->
        <div class="small-logo-box pointer" fxHide.gt-sm fxFlex="1 0 auto" fxLayoutAlign="end center" (click)="GoToDistrictHome()">
            <img class="pcps-logo" src="/assets/images/PCPS_Green_LogoOnly.svg" />
        </div>
        <div fxHide.lt-md [ngClass.md]="'medium-logo-box'" [ngClass.gt-md]="'logo-box'" class="pointer" (click)="GoToDistrictHome()">
            <img class="pcps-logo" src="/assets/images/PCPS_Green.svg" />
        </div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="scrollable">
        <div fxFlex="1 1 auto" class="routerwrapper" fxLayout="column" fxLayoutAlign="start stretch">
            <router-outlet class="content"></router-outlet>
        </div>
    </div>
</div>
